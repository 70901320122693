import React, { FC, useState } from "react"
import { Modal } from '@appkit4/react-components/modal';
import { Tag } from '@appkit4/react-components/tag';
import { Button } from '@appkit4/react-components/button';
import { TextArea } from '@appkit4/react-components/field';
import { ColourRainbow } from "components/layout/Theme";

const ParseModal: FC<{ addToList: (valueList: string[]) => void, close: () => void, open: boolean }> = ({ addToList, close, open }) => {
  const [parseData, setParseData] = useState("");
  const [valueList, setValueList] = useState(new Array<string>())
  const parseValues = () => {
    let values = parseData
      .split(/\r?\n/)
      .map(m => m.replace(/[^a-zA-Z0-9]/g, ''))
      .filter(f => f !== "");
    setParseData("");
    setValueList([...valueList, ...values]);
  }
  return (
    <Modal
      visible={open}
      title="Parse list"
      modalStyle={{ width: '50rem' }}
      footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '64px' }}
      footer={
        <>
          <Button onClick={() => close()} kind="secondary">Cancel</Button>
          <Button
            disabled={valueList.length === 0}
            onClick={() => {
              addToList(valueList)
              setParseData("");
              setValueList([]);
            }}>Add to list</Button>
        </>
      }
    >
      <p>Add values to the textfield below and parse values to a list. Most special characters will be automatically removed.</p>
      <TextArea
        title="Values"
        onChange={(value: string) => setParseData(value)}
        value={parseData}
        maxLength={420}
      />
      <Button
        onClick={parseValues}
      >Parse values</Button>
      <div
        style={{ margin: "16px 0" }}
      >
        {
          valueList.map((m, i) => <Tag size="lg" style={{ marginRight: 8, marginBottom: 8, backgroundColor: ColourRainbow[i] }} onClose={() => setValueList(valueList.filter(f => f !== m))}>{m}</Tag>)
        }
      </div>
    </Modal>
  );
}

export default ParseModal;