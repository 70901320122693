import styled from "styled-components";

interface ContainerProps {
  padding?: "none" | "sx" | "sm" | "md" | "lg"; 
}

const Container = styled.div<ContainerProps>`
  margin: 0 auto;
  min-height: 400px;
  @media(min-width: 1440px)
  {
    max-width: 1440px; 
  }
  @media(max-width: 1439px)
  {
    max-width: 1240px; 
  }
  @media(max-width: 599px)
  {
    max-width: 600px; 
  }
`;

const PageWrapper = styled.div`
@media(min-width: 1440px)
{
  margin: 40px;
}
@media(max-width: 1439px)
{
  margin: 32px;
}
@media(max-width: 599px)
{
  margin: 24px; 
}
@media(max-width: 499px)
{
  margin: 24px; 
}
`

export {
  Container,
  PageWrapper
};