import React from "react";
import { FC } from "react";
import styled, { keyframes } from "styled-components";

export const margins = {
  "none": 0,
  "sx": 24,
  "sm": 24,
  "md": 32,
  "lg": 40
}

interface GridContainerProps {
  columnTemplate: string;
  justify: string;
  margin: number | null;
  alignItems: string;
}

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: ${props => props.columnTemplate};
  column-gap: 32px;
  row-gap: 32px;
  margin: ${props => props.margin !== null ? props.margin : 40}px;
  justify-items: ${props => props.justify};
  align-items: ${props => props.alignItems};
  animation: ${FadeIn} 250ms linear;
`

interface GridProps {
  container?: boolean;
  gutter?: number;
  margin?: "none" | "sx" | "sm" | "md" | "lg"; 
  size?: "auto" | "min-content" | "max-content" | "1fr" | "2fr" | "3fr" | "4fr";
  justify?: "normal" | "center" | "start" | "end" | "tretch";
  alignSelf?: "auto" | "normal" | "stretch" | "start" | "end" | "center" | "safe" | "unsafe";
  alignItems?: "auto" | "normal" | "start" | "end" | "center" | "stretch" | "baseline" | "first baseline" | "last baseline";
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const Grid: FC<GridProps> = ({ container, gutter, margin, size, children, justify, alignItems, alignSelf, style }) => {
  const getValidChildren = (children: React.ReactNode) => 
    React.Children.toArray(children).filter(child => React.isValidElement(child)) as React.ReactElement[];

  const getSizes = (children: React.ReactNode) => getValidChildren(children)
    .map(m => m.props.size)
    .filter(f => f === "undefined" ? "auto" : f)
    .slice(0, 12)
    .join(" ");

  return (
    container 
      ? <GridContainer
          columnTemplate={getSizes(children)}
          justify={justify || "normal"}
          margin={margin ? margins[margin] : null} 
          alignItems={alignItems ? alignItems : "normal"}
          style={{ ...style }}
        >
          {children}
        </GridContainer>
      : <>
          <div
            style={{
              alignSelf: alignSelf ? alignSelf : "",
              ...style
            }}
          >
            {children}
          </div>
        </>
  )
}

export default Grid;