import React, { FC, ReactNode } from "react";
import { PageWrapper, Container } from "./Container";
import { FooterBar } from "./FooterBar";
import { HeaderBar } from "./HeaderBar";
import VerifyToken from "./VerifyToken";

const Frame: FC<{ children: ReactNode }> = ({ children }) => (
  <>
    <HeaderBar />
    <PageWrapper>
      <VerifyToken />
      <Container>
        {children}
      </Container>
    </PageWrapper>
    <FooterBar/>
  </>
);

export default Frame;
