import React, { FC, useEffect, useState } from "react"
import styled from "styled-components"
import { Progress } from "@appkit4/react-components/progress"

const ProgressWrapper = styled.div`
  width: min-content; 
  margin: 48px auto;
`

const ProgressBox: FC<{ progress: number }> = ({ progress }) => {
  const [state, setState] = useState(progress);
  useEffect(() => {
    setState(progress);
  }, [progress]);
  return(
    <ProgressWrapper>
      <Progress 
        activeIndex={state}
        space={300}
        orientation="horizontal"
        steps={["Client information", "VTJ Queries", "Process", "Upload", "Done"]}
        readonly={true}
      />
    </ProgressWrapper>
  )
}

export default ProgressBox;