import React, { FC, useEffect } from 'react';
import { Button } from '@appkit4/react-components/button';
import { useNavigate } from 'react-router-dom';
import { postData, URLS } from 'Services/ApiActions';
import { Token } from 'Types/Common';
import login from 'assets/login.svg'
import logoDark from 'assets/logoDark.svg'
import { getParameters } from 'Services/Token';
import { FooterBar } from 'components/layout/FooterBar';
import Grid from 'components/layout/Grid';

const Login: FC = () => {
  const params = getParameters(window.location.search);
  const navigate = useNavigate();
  useEffect(() => {
    async function getJWT(code: string) {
      let response = await postData<object, Token>(`authorize/${code}`, {});
      if(!response.isError && response.result?.id_token)
      {
        sessionStorage.setItem("id_token", response.result?.id_token);
        navigate("/");
      }
    }
    if(params.code) {
      getJWT(params.code);
    }
  }, [params, navigate]);
  return (
    <Grid container style={{ height: "100vh" }} margin="none">
      <Grid size="max-content" style={{ width: "60vw" }}>
        <div className="login-wrapper">
          <div className="login-logo" />
          <div className="login-body">
            <div className="form-header">
              <h3>Welcome to</h3>
              <h1>Real Estate Check</h1>
              {
                params.code
                  ? (<>
                    <p>Logging you in, please wait.</p>
                  </>)
                  : (<>
                    <Button kind="primary" onClick={() => window.location.assign(URLS.PwCIDURI)}>SSO Login</Button>
                  </>)
              }

            </div>
          </div>
          <div className="login-footer">
            <FooterBar />
          </div>
        </div>
      </Grid>
      <Grid size="1fr" >
        <div className="background-right" />
      </Grid>
    </Grid>
  )
};

export default Login;