import { makeAutoObservable, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { ApiResponse, Query, QueryStatus, Snack } from 'Types/Common';
import { getData, postData } from 'Services/ApiActions';
import { ClientInfo, IStore, QueryProcess, StoreState } from 'Types/Store';

class Store implements IStore {
  step: number = 1;
  queryStatus: QueryStatus = QueryStatus.QUEUE;
  queryProcess: QueryProcess = {}
  notifications = new Array<Snack>();
  clientInfo: ClientInfo = {};
  queryList: Query[] = [];
  storeState: StoreState = StoreState.READY;
  csvData: string = "";

  constructor() {
    makeAutoObservable(this);
  }
  resetValues() {
    this.step = 1;
    this.queryStatus = QueryStatus.QUEUE;
    this.queryProcess = {}
    this.clientInfo = {};
    this.queryList = [];
    this.storeState = StoreState.READY;
    this.csvData = "";
  }
  async postQuery(data: QueryProcess) {
    runInAction(async () => this.storeState = StoreState.LOADING
    );
    var response: ApiResponse<QueryProcess> = this.responseHandler(await postData(`/query/`, data));
    runInAction(() => {
      if (response.result?.queries) {
        this.queryList = response.result.queries;
        this.queryStatus = response.result.status || QueryStatus.PROCESSING;
        this.queryProcess = {
          ...this.queryProcess,
          status: response.result.status,
          sessionId: response.result.sessionId
        }
      }
      this.storeState = response.isError
        ? StoreState.ERROR
        : StoreState.READY;
    })
  }
  async fetchProcess() {
    runInAction(async () => {
      this.storeState = StoreState.LOADING;
    });

    var response: ApiResponse<QueryProcess> = this.responseHandler(await getData(`/query/${this.queryProcess.sessionId}/`));

    runInAction(async () => {
      if (response.result?.queries) {
        this.queryList = response.result.queries;
        this.queryStatus = response.result.status || QueryStatus.PROCESSING;
        this.queryProcess = {
          ...this.queryProcess,
        }
      }
    });

    this.storeState = response.isError
      ? StoreState.ERROR
      : StoreState.READY;
  }
  setStep(step: number) {
    runInAction(() => {
      this.step = step;
    });
  }
  addQueryList(values: string[], queryType: string) {
    runInAction(() => {
      let tempList: Query[] = values.map(m => ({ type: parseInt(queryType), value: m }));
      this.queryList = [...this.queryList, ...tempList.filter(f => !this.queryList.map(m => m.value).includes(f.value))];
    });
  }
  removeQueryList(value: string) {
    runInAction(() => {
      this.queryList = this.queryList.filter(f => f.value !== value);
    });
  }
  addNotification(notification: Snack) {
    let key = uuidv4();
    runInAction(() => {
      this.notifications = [...this.notifications, { ...notification, key: key }];
    });
  }
  removeNotification(key: string) {
    runInAction(() => {
      this.notifications = this.notifications.filter(f => f.key !== key);
    });
  }
  async fetchClient(jobId: string) {
    this.storeState = StoreState.LOADING;
    var response = this.responseHandler(await getData<ClientInfo>(`/mfiles/client/${jobId}/`));
    runInAction(() => {
      this.storeState = response.isError
        ? StoreState.ERROR
        : StoreState.READY;
      if (response.result)
        this.clientInfo = response.result
    });
    return !response.isError || false;
  }
  responseHandler<T>(response: ApiResponse<T>) {
    if (response.isError) {
      if (response.statusCode === 401) {
        this.addNotification({
          message: "Authentication error",
          options: {
            variant: "error",
            persist: true
          }
        });
      } else {
        this.addNotification({
          message: "Unexpected error happened. Try again.",
          options: {
            variant: "warning",
            persist: false
          }
        });
      }
    }
    return response;
  }
}

export default Store;