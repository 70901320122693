import { Button } from "@appkit4/react-components/button";
import Frame from "components/layout/Frame";
import Grid from "components/layout/Grid";
import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IStore } from "Types/Store";

const Welcome: FC<{ store: IStore }> = ({ store }) => {
  const navigate = useNavigate();
  useEffect(() => {
    store.resetValues();
  })
  return (
    <Frame>
      <Grid container margin="sm">
        <Grid size="2fr">
          <h2>Building Info</h2>
          <p>Retrieval of real estate and building information from the building register maintained by DVV.</p>
          <p>When using the tool, do not refresh the browser page. </p>
        </Grid>
        <Grid size="1fr" alignSelf="end">
          <Button onClick={() => navigate("/client")}>Start process</Button>
        </Grid>
      </Grid>
    </Frame>
  );
};

export default Welcome;
