import { Button } from '@appkit4/react-components/button'
import login from 'assets/login.svg'
import logoDark from 'assets/logoDark.svg'
import { FooterBar } from 'components/layout/FooterBar'
import Grid from 'components/layout/Grid'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { FC } from 'react'
import { postData, URLS } from 'Services/ApiActions'
import { Token } from 'Types/Common'

const Logout: FC = () => {
  const [logout, setLogout] = useState(false);
  useEffect(() => {
    async function logOut() {
      let response = await postData<Token, boolean>(`logout`, { id_token: sessionStorage.getItem("id_token") || ""});
      if(!response.isError && response.result)
      {
        setLogout(true);
      }
      sessionStorage.clear();
    }
    if(sessionStorage.getItem("id_token") === null)
      setLogout(true);
    if(!logout)
      logOut();
  }, [ logout ]);
  return(
    <Grid container style={{ height: "100vh" }} margin="none">
    <Grid size="max-content" style={{ width: "60vw" }}>
      <div className="login-wrapper">
        <div className="login-logo" />
        <div className="login-body">
          <div className="form-header">
            <h3>Welcome to</h3>
            <h1>Real Estate Check</h1>
            {
              !logout
                ? (<>
                    <p>Logging you in, please wait.</p>
                </>)
                : (<>
                  <p className="login-text">You have been logged out. Click below to log in again.</p>
                  <Button kind="primary" onClick={() => window.location.assign(URLS.PwCIDURI)}>SSO Login</Button>
                </>)
            }

          </div>
        </div>
        <div className="login-footer">
          <FooterBar/>
        </div>
      </div>
    </Grid>
    <Grid size="1fr" >
      <div className="background-right" />
    </Grid>
  </Grid>
  )
}
export default Logout;