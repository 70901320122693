import React, { FC } from "react";
import { Header, HeaderOptionItem } from "@appkit4/react-components/header";
import { Avatar } from "@appkit4/react-components/avatar";

import { getInitials } from "Services/Token";
import { Link } from "react-router-dom";

export const HeaderBar: FC = () => {
  const user = getInitials();
  return (
    <Header
      type="solid"
      titleTemplate={() => "Building Info Tool"}
      userTemplate={() => (
        <Avatar label={user || ""} role="button" disabled={false}></Avatar>
      )}
      contentTemplate={() => (
        <>
          <Link to="/" reloadDocument>
            <HeaderOptionItem label="Home" />
          </Link>
          <Link to="/help">
            <HeaderOptionItem label="Help" />
          </Link>
        </>
      )}
      optionsTemplate={() => {
        return (
          <Link to="/logout">
            <HeaderOptionItem              
              iconName="log-out-outline"
              label="Logout"
            ></HeaderOptionItem>
          </Link>
        );
      }}
    />
  );
};
