import React, { FC, useEffect, useState } from "react";

import Grid from "components/layout/Grid";
import ProgressBox from "components/layout/ProgressBox";
import QueryTable from "../Queries/QueryTable";
import { QueryStatus } from "Types/Common";
import Frame from "components/layout/Frame";
import { Panel } from "@appkit4/react-components/panel";
import { observer } from "mobx-react";
import { IStore, QueryProcess, StoreState } from "Types/Store";

const Process: FC<{ store: IStore }> = observer(({ store }) => {
  const [progress, setProgress] = useState(2);

  useEffect(() => {
    const postQueries = async (data: QueryProcess) => {
      if (window.confirm("Do you want to start processing the queries?")) {
        await store.postQuery(data);
      }
    };
    const queryTimer = () => setTimeout(async () => {
      await store.fetchProcess();
    }, 1000);
    switch (store.queryStatus) {
      case QueryStatus.QUEUE:
        if (store.storeState === StoreState.READY)
          postQueries({
            queries: store.queryList,
            clientId: store.clientInfo.clientId,
            jobId: store.clientInfo.jobId,
          });
        break;
      case QueryStatus.PROCESSING:
        queryTimer();
        break;
      case QueryStatus.UPLOADING:
        queryTimer();
        setProgress(3);
        break;
      case QueryStatus.DONE:
        setProgress(4);
        break;
    }
  }, [store, store.queryStatus, store.queryProcess]);
  return (
    <Frame>
      <ProgressBox progress={progress} />
      <Grid container margin="sm">
        <Grid size="2fr">
          <div>
            <h2>Process queries</h2>
            <p>
              Search results will appear on this page.
            </p>
            <p>
              Please note that if you refresh the page, you will no longer be able to track the progress of the process.
            </p>
          </div>
        </Grid>
        <Grid size="1fr"></Grid>
      </Grid>
      <QueryTable queries={store.queryList} grouped={false} />
      {store.queryStatus === QueryStatus.DONE && <Panel title="Done" style={{ marginTop: 24 }}>
        <p>
          Search results are stored in the M-Files folder based on the jobID. If no jobID is provided, the results will be stored in a default folder to which you may not have access.
        </p>
      </Panel>}
    </Frame>
  );
});

export default Process;
