import Frame from "components/layout/Frame";
import Grid from "components/layout/Grid";
import ProgressBox from "components/layout/ProgressBox";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClientBox from "./ClientBox";
import { Button } from "@appkit4/react-components/button";
import { Panel } from "@appkit4/react-components/panel";
import { Input } from '@appkit4/react-components/field';
import { observer } from "mobx-react";
import { IStore, StoreState } from "Types/Store";

const ClientInfo: FC<{ store: IStore }> = observer(({ store }) => {
  const [jobId, setJobId] = useState("");
  const navigate = useNavigate();
  return (
    <Frame>
      <ProgressBox progress={0} />
      <Grid container margin="sm">
        <Grid size="2fr">
          <div>
            <h2>Client information</h2>
            <p>
              Enter a job code below. Please be aware that the job must be classified as secret for the access to the information to be appropriately restricted.
            </p>
            <p>
              If no job is selected, choose "Proceed without job code" to store the data with limited visibility. The use of this option should be avoided whenever possible.
            </p>
          </div>
        </Grid>
        <Grid size="1fr"></Grid>
      </Grid>

      <Panel title="Find client">
        <Grid container margin="none">
          <Grid size="1fr">
            <Input
              type="text"
              placeholder=""
              title="Job id"
              value={jobId}
              onChange={(e: string) => {
                setJobId(e);
              }}
              onKeyDown={async (
                event: React.KeyboardEvent<HTMLInputElement>
              ) => {
                if (event.key === "Enter") await store.fetchClient(jobId);
              }}
            >
              <div
                aria-live="polite"
                className="ap-field-email-validation-suggestion"
              >
                Please fill in the job identifier to proceed.
              </div>
            </Input>
          </Grid>
          <Grid size="1fr">
            <Button
              onClick={async () => {
                await store.fetchClient(jobId);
              }}
              disabled={store.storeState === StoreState.LOADING}
            >
              {store.storeState === StoreState.LOADING ? "Searching" : "Search"}
            </Button>
          </Grid>
        </Grid>
      </Panel>
      {store.clientInfo.clientName && <ClientBox client={store.clientInfo} />}
      <Grid container margin="sx">
        <Grid size="min-content">
          <Button
            onClick={() => navigate("/queries")}
            disabled={store.clientInfo.clientName == null}
          >
            Proceed
          </Button>
        </Grid>
        <Grid size="min-content">
          <Button
            onClick={() => navigate("/queries")}
            kind="secondary"
          >
            Proceed without job code
          </Button>
        </Grid>
      </Grid>
    </Frame>
  );
});

export default ClientInfo;
