import { Accordion, AccordionItem } from "@appkit4/react-components/accordion";
import { Panel } from "@appkit4/react-components/panel";
import Frame from "components/layout/Frame";
import Grid from "components/layout/Grid";
import React, { FC } from "react";

const Help: FC = () => {
  const [activeKeys, setActiveKeys] = React.useState<string[]>(["1"]);
  return (
    <Frame>
      <Grid container margin="sm">
        <Grid size="2fr">
          <h2>Help</h2>
          <p>
            Here is the link to the project's sharepoint folder.
          </p>
        </Grid>
        <Grid
          size="1fr"
        >

        </Grid>
      </Grid>
      <Panel
        title="FAQ"
      >
        <Accordion
          style={{ background: "#ffffff" }}
          multiple={true}
          onClick={(keys: string[]) => setActiveKeys(keys)}
          activeKeys={activeKeys}
        >
          <AccordionItem title={"How much do searches cost? "} itemKey="1">
            <span className="AccordionItem-text">
              Each search costs 0.20 euros per building code. The most recent prices can be found on the DVV website.
            </span>
          </AccordionItem>
          <AccordionItem title={"Why does Job have to be secret? "} itemKey="3">
            <span className="AccordionItem-text">
              The service provider demands that the access to data is limited. Additionally, logs of searches are created, which are handed over to the service provider upon request.
            </span>
          </AccordionItem>
        </Accordion>
      </Panel>
    </Frame>
  );
};
export default Help;
