import { AxiosError, AxiosResponse } from "axios";
import { ApiResponse } from "Types/Common"
import axios from 'axios';

export const STORAGE_KEYS = {
  // Key for id_token
  TOKEN: 'id_token',
  // Key for denied_url
  DENIED_URL: 'denied_url',
};

export const URLS = {
  PwCIDURI: process.env.REACT_APP_PwCID_URI || "",
  APIURI: process.env.REACT_APP_API_URL || ""
}

const instance = axios.create({
  baseURL: URLS.APIURI,
  timeout: 30000,
  withCredentials: true
});
instance.interceptors.request.use((request) => {
  if(request.headers)
    request.headers['Authorization'] = `Bearer ${sessionStorage.getItem("id_token")}`;
  return request;
});
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  }, 
  (error: AxiosError) => {
    if(error.response && [401, 403].includes(error.response?.status))
    {
      window.location.href = "/login";
    }
  });

export const getData = async <T>(endpoint: string): Promise<ApiResponse<T>> =>  {
  try {
    const { data } = await instance.get(endpoint);
    return data as ApiResponse<T>;
  } catch (error) {
    if(axios.isAxiosError(error))
      return ({isError: true, statusCode: error.response?.status||500 ,...error})
  }
  return ({isError: true, statusCode: 500})
}
export const postData = async <T, U>(endpoint: string, payload: T): Promise<ApiResponse<U>> => {
  try {
    const { data } = await instance.post(endpoint, payload)
    return data;
  } catch (error) {
    if(axios.isAxiosError(error))
      return ({isError: true, statusCode: error.response?.status||500 ,...error})
  }
  return ({isError: true, statusCode: 500}) 
}

export const putData = async <T, U>(endpoint: string, payload: T): Promise<ApiResponse<U>> => {
  try {
    const { data } = await instance.put(endpoint, payload)
    return data;
  } catch (error) {
    if(axios.isAxiosError(error))
      return ({isError: true, statusCode: error.response?.status||500 ,...error})
  }
  return ({isError: true, statusCode: 500}) 
}
export const deleteData = async <T>(endpoint: string): Promise<ApiResponse<T>> => {
  try {
    const { data } = await instance.delete(endpoint)
    return data;
  } catch (error) {
    if(axios.isAxiosError(error))
      return ({isError: true, statusCode: error.response?.status||500 ,...error})
  }
  return ({isError: true, statusCode: 500}) 
}