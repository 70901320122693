import { Query, QueryStatus, Snack } from "./Common";

export const QueryTypes = [
    { value: 0, label: "Rakennustunnus" }
  ]
  export enum StoreState {
    READY,
    LOADING,
    ERROR
  }
  
  export type ClientInfo = {
    jobId?: string;
    clientId?: string;
    clientName?: string;
    jobName?: string;
  }
  export type QueryFields = {
    queryType?: string;
    Rakennustunnus?: string;
    Huoneistotunnus?: string;
    HakuXml?: string;
    Kiinteistotunnus?: string;
  }
  export type QueryProcess = {
    sessionId?: string;
    user?: string;
    queries?: Query[];
    status?: QueryStatus;
    jobId?: string;
    clientId?: string;
  }
  
  export interface IStore {
    storeState: StoreState;
    csvData: string;
    step: number;
    queryList: Query[];
    queryProcess: QueryProcess;
    clientInfo: ClientInfo;
    queryStatus: QueryStatus;
    notifications: Snack[];
    setStep: (step: number) => void;
    resetValues: () => void;
    postQuery: (data: QueryProcess) => void;
    addQueryList: (insertList: string[], type: string) => void;
    removeQueryList: (value: string) => void;
    addNotification: (notification: Snack) => void;
    removeNotification: (key: string) => void;
    fetchClient: (jobId: string) => Promise<boolean>;
    fetchProcess: () => void;
  }