export const ColourRainbow = [
  "#62719A",
  "#E45C2B",
  "#27897E",
  "#E27588",
  "#E44F3F",
  "#415385",
  "#D04A02",
  "#26776D",
  "#D93954",
  "#E0301E",
  "#203570",
  "#C34C2F",
  "#245952",
  "#B5485B",
  "#C22D1D",
  "#1A2A5A",
  "#A7452C",
  "#223937",
  "#903F4D",
  "#A62B1E"
]