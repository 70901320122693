import { Params, JWTToken, Token, TokenStatus } from "Types/Common";
import jwt_decode from 'jwt-decode';
import { postData } from "./ApiActions";

export const getParameters = (queryString: string): Params => {
  let params = {};
  let pairs = queryString.substring(1).split("&");

  pairs.forEach(m => {
    let vals = m.split("=");
    Object.assign(params, { [vals[0]]: vals[1] });
  });
  return params;
}
export const getUserInfo = () => {
  var token: string | null = sessionStorage.getItem("id_token");
  if(!token) return null;
  var decodedToken: JWTToken = jwt_decode(token);
  return decodedToken;
}
export const getInitials = () => {
  var user = getUserInfo();
  if(!user) return null
  return `${user.given_name.charAt(0)}${user.family_name[0].charAt(0)}`
}
export const checkIfExpired = () => {
  var token: string | null = sessionStorage.getItem("id_token");
  if(!token) return TokenStatus.NO_TOKEN;
  var decodedToken: JWTToken = jwt_decode(token);
  var currentTime = Math.round((new Date()).getTime() / 1000);
  return (decodedToken.exp - currentTime) > 0
    ? TokenStatus.OK
    : TokenStatus.EXPIRED;
}
export const refreshToken = async () => {
  let response = await postData<object, Token>(`refresh`, {});
  if(!response.isError && response.result?.id_token)
  {
    sessionStorage.setItem("id_token", response.result?.id_token);
  } else
  {
    window.location.href = "/login";
  }
}