import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkIfExpired, refreshToken } from "Services/Token";
import { TokenStatus } from "Types/Common";


const VerifyToken:FC = () => {
  let navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    if(location.pathname.includes("login")) return;
    let status = checkIfExpired();
    switch (status) {
      case TokenStatus.NO_TOKEN:
        navigate("/login");
        break;
      case TokenStatus.EXPIRED:
        refreshToken();
        break;
      case TokenStatus.OK:
        break;
    }
  })
  return null;
} 

export default VerifyToken;