import { Footer } from '@appkit4/react-components/footer';
import { FC } from "react";

export const FooterBar:FC = () => {
  const footerContent = "© 2024 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <a class='ap-link' href='https://www.pwc.com/structure'>www.pwc.com/structure</a> for further details.";
  return (
    <div style={{ padding: "20px 32px"}}>
      <Footer content={footerContent} type="text"/>
    </div>
  );
}