import { Panel } from "@appkit4/react-components/panel";
import { Tag } from "@appkit4/react-components/tag";
import { ColourRainbow } from "components/layout/Theme";
import React, { FC } from "react";
import { ClientInfo } from "Types/Store";

const ClientBox: FC<{ client: ClientInfo }> = ({ client }) => {
  return (
    <Panel
      title="Client information"
      style={{ marginTop: 24, marginBottom: 24, padding: "8px 0" }}
    >
      {
        <>
          <Tag
            size="lg"
            closable={false}
            style={{ marginBottom: 16, marginLeft: 8 }}
            color={ColourRainbow[1]}
          >
            {client.jobId}
          </Tag>
          <Tag
            size="lg"
            closable={false}
            style={{ marginBottom: 16, marginLeft: 8 }}
            color={ColourRainbow[0]}
          >
            {client.clientName}
          </Tag>
          <Tag
            size="lg"
            closable={false}
            style={{ marginBottom: 16, marginLeft: 8 }}
          >
            {client.jobName}
          </Tag>
        </>
      }
    </Panel>
  );
};

export default ClientBox;
