import React from 'react';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';

import Layout from 'components/Routes';
import 'styles.scss';
import { BrowserRouter } from 'react-router-dom';

const rootElement = document.getElementById('root');

if (!rootElement) throw new Error('Failed to find the root element');

const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  </StrictMode>,
);

