import React, { FC } from "react";

import { Query, QueryStatus } from "Types/Common";
import { ColourRainbow } from "components/layout/Theme";
import { QueryTypes } from "Types/Store";
import { Column, Table, Tag } from "@appkit4/react-components";

type RowType = { type: number; values: string; status: number };

const QueryTable: FC<{
  queries: Query[];
  grouped?: boolean;
  removeValue?: (value: string) => void;
}> = ({ queries, grouped = true, removeValue }) => {
  const data = grouped
    ? QueryTypes.map((t, i) =>
        queries.filter((f) => f.type === i).length > 0
          ? {
              type: QueryTypes[i].label,
              status: QueryStatus.QUEUE,
              values: queries
                .filter((f) => f.type === i)
                .map((m) => m.value)
                .join(", "),
            }
          : null
      ) || []
    : queries.map((m) => ({
        type: QueryTypes[m.type].label,
        values: m.value,
        status: m.status || QueryStatus.QUEUE,
      })) || [];
  const renderStatus = (row: RowType) => {
    return (
      <Tag
        size="lg"
        style={{
          marginRight: 8,
          backgroundColor: ColourRainbow[row.status],
          marginBottom: 8,
        }}
        closable={false}
      >
        {QueryStatus[row.status]}
      </Tag>
    );
  };
  const renderValues = (row: RowType, field: string) => {
    if (grouped)
      return row.values.split(", ").map((value, i) => (
        <Tag
          key={`${value}-${i}`}
          size="lg"
          style={{
            marginRight: 8,
            backgroundColor: ColourRainbow[i],
            marginBottom: 8,
          }}
          closable={grouped}
          onClose={() => removeValue && removeValue(value)}
        >
          {value}
        </Tag>
      ));
    else return row.values;
  };
  // const renderButton = (row: number) => {
  //   return(
  //     <Button
  //       kind='primary'
  //       onClick={async () => {
  //         if(store.storeState === StoreState.LOADING) return;
  //         let data: any = await store.postQuery(queries.filter(f => QueryTypes[f.Type].label === data[row].type));
  //         fileDownload(data, `RAKPE2_${uuidv4()}.csv`);
  //       }
  //       }>
  //         {store.storeState === StoreState.LOADING ? "Processing" : "Download"}
  //     </Button>
  //   )
  // }

  return (
    <Table
      originalData={data || []}
      striped
      hasTitle
      title="Query list"
      style={{ marginTop: "24px" }}
    >
      <Column field="type">Type</Column>
      <Column field="values" renderCell={renderValues}>
        Values
      </Column>
      <Column field="status" renderCell={renderStatus} >
        Status
      </Column>
    </Table>
  );
};

export default QueryTable;
