import { Button } from "@appkit4/react-components/button";
import { SelectValue } from "@appkit4/react-components/esm/select/Select";
import { Input } from "@appkit4/react-components/field";
import { Panel } from "@appkit4/react-components/panel";
import { Select } from "@appkit4/react-components/select";
import Grid from "components/layout/Grid";
import React, { FC, useState } from "react";
import { IStore, QueryTypes } from "Types/Store";
import ParseModal from "./ParseModal";

const QueryBox: FC<{ store: IStore }> = ({ store }) => {
  const [queryType, setQueryType] = useState<SelectValue>('');
  const [queryField, setQueryField] = useState("");
  const [open, setOpen] = useState(false);

  const addToList = () => {
    if (typeof queryType === "undefined") return;
    let tempList = [queryField.replace(",", "")].filter((f) => f !== "")
    store.addQueryList(tempList, queryType.toString());
    setQueryField("");
  };

  const handleChange = (value: string) => {
    if (typeof queryType === "undefined") return;
    if (value.includes(",") && value.length > 1) {
      let values = value.split(",").map((m) => m.trim().replaceAll(",", ""));
      let tempList = values.filter((f) => f !== "");
      store.addQueryList(tempList, queryType.toString());
      setQueryField("");
    } else {
      setQueryField(value);
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
    if (typeof queryType === "undefined") return;
    if (event.key === "Enter" && queryField.length > 0) {
      store.addQueryList([queryField], queryType.toString());
      setQueryField("");
    }
  };

  return (
    <>
      <Panel title="Add queries">
        <Grid container margin="none">
          <Grid size="max-content">
            <Select
              required
              placeholder="Query type"
              data={QueryTypes}
              value={queryType}
              searchable={false}
              onSelect={(value) => setQueryType(value)}
            />
          </Grid>
          <Grid size="1fr">
            <Input
              type="text"
              title={"Values"}
              value={queryField}
              disabled={typeof queryType === "undefined"}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
            >
              <div
                aria-live="polite"
                className="ap-field-email-validation-suggestion"
              >
                Would you like to add <span onClick={() => setOpen(true)}>row data</span>?
              </div>
            </Input>
          </Grid>
          <Grid size="1fr">
            <Button
              style={{ marginRight: 8 }}
              onClick={addToList}
              disabled={queryType === ''}
            >
              Add to list
            </Button>
          </Grid>
        </Grid>
        {/* {insertList.length > 0 ? (
          <>


            <Button
              kind="secondary"
              style={{ display: "inline" }}
              onClick={() => setInsertList([])}
            >
              Clear
            </Button>
          </>
        ) : (
          <p>No queries yet.</p>
        )} */}
      </Panel>
      <ParseModal
        addToList={(valueList: string[]) => {
          if (typeof queryType === "undefined" || valueList.length === 0)
            return;
          store.addQueryList(valueList, queryType.toString());
          setOpen(false);
        }}
        open={open}
        close={() => setOpen(false)}
      />
    </>
  );
};

export default QueryBox;