import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "components/Steps/Login/Login";
import Store from "Store/Store";
import Queries from "./Steps/Queries/Queries";
import ClientInfo from "./Steps/Client/ClientInfo";
import Process from "./Steps/Process/Process";
import Welcome from "./Steps/Welcome/Welcome";
import Help from "./Steps/Help/Help";
import Logout from "components/Steps/Login/Logout";

const store = new Store();

const Main: FC = () => (
  <>
    <Routes>
      <Route path="/" element={<Welcome store={store} />} />
      <Route path="/client" element={<ClientInfo store={store} />} />
      <Route path="/queries" element={<Queries store={store} />} />
      <Route path="/process" element={<Process store={store} />} />
      <Route path="/help" element={<Help />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  </>
);

export default Main;
