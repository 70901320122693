import React, { FC } from "react";
import { Button} from "@appkit4/react-components/button";

import Grid from "components/layout/Grid";
import ClientBox from "../Client/ClientBox";
import ProgressBox from "components/layout/ProgressBox";
import { useNavigate } from "react-router-dom";
import QueryTable from "./QueryTable";
import QueryBox from "./QueryBox";
import Frame from "components/layout/Frame";
import { observer } from "mobx-react";
import { IStore } from "Types/Store";

const Queries: FC<{ store: IStore }> = observer(({ store }) => {
  const navigate = useNavigate();
  return (
    <Frame>
      <ProgressBox progress={1} />
      <Grid container margin="sm">
        <Grid size="2fr">
          <div>
            <h2>Specify VTJ query</h2>
            <p>
            Choose the correct search type and input the necessary search credentials. For instance, when applying with a building ID, input the permanent building code of the desired building.  
            </p>
            <p>
            If you wish to add multiple search credentials, you can either add them individually or select "add row data", which allows you to add a list of IDs for the search. In this case, the search IDs can be inserted as a list. By selecting 'parse', the program will read the IDs you have entered.  
            </p>
            <p>
            The searchable IDs will appear below under “Values”. So, before pressing "Proceed" make sure that all the search credentials you want are in "Values". 
            </p>
          </div>
        </Grid>
        <Grid size="1fr"></Grid>
      </Grid>
      { store.clientInfo.clientName && <ClientBox client={store.clientInfo} />}
      <Grid container margin="none">
        <Grid size="2fr">
          <QueryBox store={store}/>
        </Grid>
      </Grid>
      {store.queryList.length > 0 && <QueryTable queries={store.queryList} removeValue={(value) => store.removeQueryList(value)}/>}
      <Grid container margin="sm">
        <Grid size="1fr">
          <Button
            style={{ width: 128, marginLeft: "-6px" }}
            onClick={() => navigate("/process")}
            disabled={store.queryList.length === 0}
          >
            Proceed
          </Button>
        </Grid>
      </Grid>
      </Frame>
  );
});

export default Queries;
